/* Copyright 2021, Milkdown by Mirone. */
export const typography = [
    'Roboto',
    'HelveticaNeue-Light',
    'Helvetica Neue Light',
    'Helvetica Neue',
    'Helvetica',
    'Arial',
    'Lucida Grande',
    'sans-serif',
];

export const code = ['Consolas', 'Monaco', 'Andale Mono', 'Ubuntu Mono', 'monospace'];
